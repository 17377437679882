import React, { useState, useRef, useEffect, useContext } from 'react';
import { getFirestore, runTransaction, addDoc, deleteDoc, setDoc, serverTimestamp, doc, getDoc, collection, query, where, getDocs, orderBy, startAfter, limit } from 'firebase/firestore';
import { AppContext } from './AppContext';
import { firestore } from './firebase';
import { getAuth } from "firebase/auth";
import { useNavigate } from 'react-router-dom';
import ProfilesDisplaySharePost from './ProfilesDisplaySharePost';
import LikeIcon from './assets/LikeIcon.png'; // Import the image
import LikeIconActive from './assets/LikeIconActive.png'; // Import the active image
import CommentIcon from './assets/CommentIcon.png'; // Import other icons similarly
import RepostIcon from './assets/RepostIcon.png';
import RepostIconActive from './assets/RepostIconActive.png';
import ellipsis from './assets/ellipsis-v.png';
import PlayButton from './assets/PlayButton.png';
import ArtistIcon1 from './assets/ArtistIcon1.png';
import ArtistIcon2 from './assets/ArtistIcon2.png';
import Modal from 'react-modal';
import { ProfilesDisplayShareDATpost } from './ProfilesDisplayShareDATpost';
import { SharePostPopup } from './SharePostPopup';
import { getFunctions, httpsCallable } from 'firebase/functions';

const CACHE_DURATION = 12;
const bottomAreaHeight = window.innerHeight * 0.3;
const scrollAreaTopPadding = window.innerHeight * 0.17;

const Social = ({ onProfilePress, onMoveMP }) => {
  const [refreshing, setRefreshing] = useState(false);
  const [viewMode, setViewMode] = useState('horizontal');
  const { showSharePostPopup, setShowSharePostPopup, showMediaPopup, currentUser, setCurrentlyLoadingProfile, setViewingProfile, setActivePopupMedia, activePopupMedia, setTrackToView, setIsExpandedShare, setIsLoadingHideEA, setInitiallySelectedItemId, setActiveTab, setIsExpanded, setIsLoadingArtwork, setIsLoadingProfilePicture, setIsLoadingExpand, setIsLoadingNewPopup, setArtworkUri, setInitialExpandedAlbumAndSong, setCenteredRelease, setHideExpandedAlbum, userId2, likes, setLikes, posts, setPosts, reposts, setReposts, sharePostActive, setSharePostActive, postToShare, setPostToShare, following } = useContext(AppContext);
  const [profiles, setProfiles] = useState([]);
  const [isLoadingPosts, setIsLoadingPosts] = useState(false);

const videoRefs = useRef({});


  const navigate = useNavigate();

  const [likedPosts, setLikedPosts] = useState({});
  const [lastVisiblePost, setLastVisiblePost] = useState(null);
  const [loadingMore, setLoadingMore] = useState(false);
  const [allPostsLoaded, setAllPostsLoaded] = useState(false);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentImage, setCurrentImage] = useState(null);
  const [currentVideoUri, setCurrentVideoUri] = useState(null);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeItemId, setActiveItemId] = useState(null);
  const [reportComplete, setReportComplete] = useState(false);
  const [sharePost, setSharePost] = useState(false);
  const [currentPost, setCurrentPost] = useState(null);

  const viewabilityConfig = {
    itemVisiblePercentThreshold: 50  // Item needs to be 50% visible to trigger playback
  };

  const onViewableItemsChanged = useRef(({ viewableItems }) => {
    viewableItems.forEach(item => {
      const ref = videoRefs.current[item.item.imageUri];
      if (ref) {
        ref.playAsync();
      }
    });
    Object.keys(videoRefs.current).forEach(key => {
      if (!viewableItems.find(item => item.item.imageUri === key)) {
        if (videoRefs.current[key]) {
          videoRefs.current[key].pauseAsync();
        }
      }
    });
  }).current;

  const sortReleasesByTimestamp = (releases) => {
    const sortedReleases = [...releases].sort((a, b) => b.releaseTimestamp - a.releaseTimestamp);
  
    return sortedReleases;
  };

  const preparePopupData = (userData, userId) => {
    
     
     
    const albums = sortReleasesByTimestamp(userData.albums || []);
    const singles = sortReleasesByTimestamp(userData.singles || []);
    const videos = sortReleasesByTimestamp(userData.videos || []);
  
    let profileImageUrl = null;
  
  
  
    const popupData = {
      artistName: userData.artistName || userData.ArtistName,
      popupProfileImage: userData?.profileImageUrl,
      username: userData.username,
      accountType: userData.accountType,
      userId: userId,
      otherReleases: { albums, singles, videos },
      vocalDrop: userData.vocalDrop,
      voiceDropMuted: userData?.voiceDropMuted,
      bio: userData.bio,
      blurb: userData.blurb,
      location: userData.location,
      timestamp: new Date().getTime(),
      ScreenSKN: userData.ScreenSKN,
    };
  
    
    return popupData;
  };

  const handleProfileSelect = async (profileData, userId) => {

    try {
      
      setIsSelecting(true);
      const popupData = preparePopupData(profileData, userId);
      setActivePopupMedia(popupData); // This triggers the useEffect
      
    } catch (error) {
      console.error('handleProfileSelect: Error in handling profile selection:', error);
      setCurrentlyLoadingProfile(false);
    }
  };

  const handleRepost = async (post, newUserText, newUserImageUri) => {
    const db = getFirestore();
    const auth = getAuth();
    const user = auth.currentUser;

    if (!user || !post) {
        console.error("User or post is undefined");
        return;
    }

    if (!currentUser) {
        console.error("Current user data is not available");
        return;
    }

    const targetPostId = post.originalPost ? post.originalPost.id : post.id;
    const originalPostRef = doc(db, 'posts', targetPostId);
    const userRepostsRef = doc(db, `users/${user.uid}/reposts`, targetPostId);

    const alreadyReposted = !!reposts[targetPostId];

    await runTransaction(db, async (transaction) => {
        const originalPostDoc = await transaction.get(originalPostRef);
        if (!originalPostDoc.exists()) {
            console.error("Original post does not exist!");
            return;
        }

        const userRepostDoc = await transaction.get(userRepostsRef);
        if (userRepostDoc.exists()) {
            const repostId = userRepostDoc.data().repostId;
            const existingRepostRef = doc(db, 'posts', repostId);

            transaction.delete(existingRepostRef);
            transaction.delete(userRepostsRef);

            const updatedRepostCount = Math.max((originalPostDoc.data().repostCount || 0) - 1, 0);
            transaction.update(originalPostRef, { repostCount: updatedRepostCount });

            setPosts(prevPosts => prevPosts.map(p => {
                if (p.id === targetPostId) {
                    return {...p, repostCount: updatedRepostCount};
                } else if (p.postId === repostId) {
                    return null; // Removes the specific repost without affecting other posts
                }
                return p;
            }).filter(p => p != null));
            setReposts(prevReposts => {
                const updatedReposts = {...prevReposts};
                delete updatedReposts[targetPostId];
                return updatedReposts;
            });
        } else {
            const repostRef = doc(collection(db, 'posts'));
            const repostData = {
                postId: repostRef.id,
                text: newUserText || '',
                imageUri: newUserImageUri || '',
                userId: user.uid,
                username: currentUser.username,
                accountType: currentUser.accountType,
                name: currentUser.name,
                profileImageUrl: currentUser.profileImageUrl,
                createdAt: serverTimestamp(),
                originalPost: {
                    id: targetPostId,
                    postId: originalPostDoc.data().postId,
                    userId: originalPostDoc.data().userId,
                    username: originalPostDoc.data().username,
                    name: originalPostDoc.data().name,
                    mediaType: originalPostDoc.data().mediaType ? originalPostDoc.data().mediaType : '',
                    profileImageUrl: originalPostDoc.data().profileImageUrl,
                    text: originalPostDoc.data().text,
                    imageUri: originalPostDoc.data().imageUri,
                    createdAt: originalPostDoc.data().createdAt,
                    ...(originalPostDoc.data().accountType ? { accountType: originalPostDoc.data().accountType } : ''),
                    ...(originalPostDoc.data().userRef ? { userRef: originalPostDoc.data().userRef } : ''),
                    ...(originalPostDoc.data().profile ? { profile: originalPostDoc.data().profile } : {}),
                    ...(originalPostDoc.data().media ? { media: originalPostDoc.data().media } : {})
                },
                likeCount: 0,
                repostCount: 0,
                likes: {},
                comments: [],
                repostUserIds: []
            };

            transaction.set(repostRef, repostData);
            transaction.set(userRepostsRef, { originalPostId: targetPostId, repostId: repostRef.id });

            const updatedRepostCount = (originalPostDoc.data().repostCount || 0) + 1;
            transaction.update(originalPostRef, { repostCount: updatedRepostCount });

            setPosts(prevPosts => [...prevPosts.map(p => p.id === targetPostId ? {...p, repostCount: updatedRepostCount} : p)]);
            setReposts(prevReposts => ({...prevReposts, [targetPostId]: true}));
        }
    });

    
  };



  const handleRepostDeletion = (postId) => {
    const repostId = reposts[postId];
    if (repostId) {
      setReposts(reposts => {
        const updatedReposts = { ...reposts };
        delete updatedReposts[postId];
        return updatedReposts;
      });
    }
  };

  const fetchLikes = async (posts) => {
    const db = getFirestore();
    const userId = getAuth().currentUser.uid;
    const likedPostsQuery = query(collection(db, 'likes'), where('userId', '==', userId));
    const querySnapshot = await getDocs(likedPostsQuery);
    const likes = {};
    querySnapshot.forEach((doc) => {
      likes[doc.data().postId] = true;
    });
    setLikedPosts(likes);
  };

  useEffect(() => {
    if (isSelecting && activePopupMedia) {
      onProfilePress();
      setCurrentlyLoadingProfile(false);
      setIsSelecting(false);
    }
  }, [activePopupMedia]);

  const postsLimit = 7;

  const lastVisiblePostRef = useRef(null);
  const [showActivityIndicator, setShowActivityIndicator] = useState(false);

  const fetchPosts = async (loadMore = false) => {
  
    if (!following?.length) {
      console.log('No users to follow.');
      setPosts([]);
      setAllPostsLoaded(true);
      return;
    }
  
    if (loadMore && allPostsLoaded) {
      console.log('No more posts to load.');
      return;
    }
  
    if (isLoading) {
      console.log('Fetch already in progress.');
      return;
    }
  
    setIsLoading(true);
    setShowActivityIndicator(!loadMore && posts.length === 0);
    const db = getFirestore();
    const chunks = [];
    for (let i = 0; i < following.length; i += 30) {
      chunks.push(following.slice(i, i + 30));
    }
  
    try {
      // Fetch all chunks in parallel
      const promises = chunks.map(async (chunk) => {
        let postsQuery = query(
          collection(db, 'posts'),
          where('userId', 'in', chunk),
          orderBy('createdAt', 'desc'),
          limit(postsLimit)
        );
  
        if (loadMore && lastVisiblePostRef.current) {

          postsQuery = query(postsQuery, startAfter(lastVisiblePostRef.current));
        }
  
        const querySnapshot = await getDocs(postsQuery);
        const fetchedPosts = querySnapshot.docs.map(doc => ({ ...doc.data(), postId: doc.id }));
        return {
          fetchedPosts,
          lastDoc: querySnapshot.docs[querySnapshot.docs.length - 1],
        };
      });
  
      const results = await Promise.all(promises);
  
      let allFetchedPosts = [];
      let lastVisibleDocs = [];
      results.forEach(result => {
        allFetchedPosts.push(...result.fetchedPosts);
        if (result.lastDoc) {
          lastVisibleDocs.push(result.lastDoc);
        }
      });
  
      // Sort all fetched posts by createdAt descending
      allFetchedPosts.sort((a, b) => b.createdAt - a.createdAt);
  
      setPosts(prevPosts => {
        const newPosts = allFetchedPosts.filter(newPost => !prevPosts.some(p => p.postId === newPost.postId));
        return loadMore ? [...prevPosts, ...newPosts] : allFetchedPosts;
      });
  
      // Update lastVisiblePostRef.current for pagination
      if (lastVisibleDocs.length > 0) {
        // Pick the earliest createdAt among the last documents
        lastVisibleDocs.sort((a, b) => a.data().createdAt - b.data().createdAt);
        lastVisiblePostRef.current = lastVisibleDocs[0];

      }
  
      setAllPostsLoaded(allFetchedPosts.length < postsLimit);
    } catch (error) {
      console.error("Error fetching posts:", error);
    } finally {
      setIsLoading(false);
      setShowActivityIndicator(false);
    }
  };
  

  useEffect(() => {
    if (following?.length > 0 && !isLoadingPosts) {
      fetchPosts();
    }
  }, [following]);

  const onRefresh = React.useCallback(() => {
    setRefreshing(true);
    fetchPosts().then(() => setRefreshing(false));
  }, [following]);

  const handleLikePress = async (postId) => {
    const auth = getAuth();
    const user = auth.currentUser;
    if (!user) {
      console.error("No user logged in");
      return;
    }

    const db = getFirestore();
    const postRef = doc(db, 'posts', postId);
    const userLikesRef = doc(db, `users/${user.uid}/likes`, postId);
    const likesCollectionRef = collection(db, 'likes');
    const likeDocRef = doc(likesCollectionRef, `${user.uid}_${postId}`);

    setPosts((currentPosts) => {
      const postIndex = currentPosts.findIndex(p => p.postId === postId);
      if (postIndex !== -1) {
        const updatedPosts = [...currentPosts];
        const currentPost = updatedPosts[postIndex];
        const currentlyLiked = currentPost.likes && currentPost.likes[user.uid];

        if (currentlyLiked) {
          currentPost.likeCount = Math.max(currentPost.likeCount - 1, 0);
          delete currentPost.likes[user.uid];
        } else {
          currentPost.likeCount += 1;
          currentPost.likes = currentPost.likes || {};
          currentPost.likes[user.uid] = true;
        }
        updatedPosts[postIndex] = currentPost;

        return updatedPosts;
      }
      return currentPosts;
    });

    setLikes((likedPosts) => ({
      ...likedPosts,
      [postId]: !(likedPosts && likedPosts[postId])
    }));

    try {
      await runTransaction(db, async (transaction) => {
        const postDoc = await transaction.get(postRef);
        if (!postDoc.exists()) {
          throw new Error("Post document does not exist!");
        }

        const postData = postDoc.data();
        const userLikeDoc = await transaction.get(userLikesRef);
        const likeDoc = await transaction.get(likeDocRef);
        const likesMap = postData.likes || {};
        let newLikeCount = postData.likeCount || 0;

        if (likeDoc.exists()) {
          transaction.delete(userLikesRef);
          transaction.delete(likeDocRef);
          if (postData.likeCount > 0) {
            newLikeCount -= 1;
          }
          if (likesMap[user.uid]) {
            delete likesMap[user.uid];
          }
        } else {
          transaction.set(userLikesRef, { createdAt: serverTimestamp() });
          transaction.set(likeDocRef, {
            postId: postId,
            userId: user.uid,
            creatorId: postData.userId,
            name: currentUser.name,
            profileImageUrl: currentUser?.profileImageUrl ? currentUser?.profileImageUrl : '',
            createdAt: serverTimestamp()
          });
          likesMap[user.uid] = true;
          newLikeCount += 1;
        }

        transaction.update(postRef, {
          likeCount: newLikeCount,
          likes: likesMap
        });
      });
      
    } catch (error) {
      console.error("Transaction failed: ", error);
    }
  };

  const handlePressPost = (post) => {
    // Clone the post object to ensure it only contains cloneable data
    const clonedPost = JSON.parse(JSON.stringify(post));
    navigate('/viewpost', {
      state: {
        post: clonedPost,
        isLiked: !!likes[post.id],
        isReposted: !!reposts[post.id]
      }
    });
  };

  const handlePressPostComment = (post) => {
    // Clone the post object to ensure it only contains cloneable data
    const clonedPost = JSON.parse(JSON.stringify(post));
    navigate('/viewpost', {
      state: {
        post: clonedPost,
        isLiked: !!likes[post.id],
        isReposted: !!reposts[post.id],
        focusCommentInput: true
      }
    });
  };
  
  const handlePressOriginalPost = (originalPost) => {
    // Clone the originalPost object to ensure it only contains cloneable data
    const clonedOriginalPost = JSON.parse(JSON.stringify(originalPost));
    navigate('/viewpost', {
      state: {
        post: clonedOriginalPost,
        isLiked: !!likes[originalPost.id],
        isReposted: !!reposts[originalPost.id],
        isOriginalFromRepost: true
      }
    });
  };


  const getCachedUserDocSHARE = async (userId) => {
    let userDataCached = [];
    let userDataCachedNew = [];
      
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);
  
      if (userDocSnap.exists()) {
        
        const freshUserData = userDocSnap.data();
  
        
  
  
  
        // Update userDataCached structure with fresh data and timestamp, keep old local URLs initially.
        userDataCachedNew = {
          ...freshUserData, // overwrite with fresh data from Firestore
          profileImageUrl: freshUserData.profileImageUrl,
        };
  
  
  
        // Persist any updates in the cache and database
  
      } else {
        console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);
      }
    
  
    return userDataCachedNew;
  };

  const handleNavigateMovie = async (movie) => {
    navigate('/movieview', { state: { movie: movie } } );
  };

  const handleMove = (media) => {

    if (media?.MovieTitle || media?.isTrailer)  {
      handleNavigateMovie(media);
      } else {

    handlePressBeginsSHARE(media);
      }
  };

  const handlePressBeginsSHARE = async (song) => {
    const fetchedUserData = await getCachedUserDocSHARE(song.artistUserId);
    const profileData = fetchedUserData;
    const updatedProfileData = await updateSongWithProfileData(profileData, song);
    
    if (updatedProfileData) {
      const clonedSong = JSON.parse(JSON.stringify(updatedProfileData));
      navigate('/view-song', { state: { song: clonedSong } });
    }
  };
  
  const updateSongWithProfileData = async (profileData, song) => {
    const { singles = [], albums = [], videos = [], spncasts = [] } = profileData;
    const allReleases = [...singles, ...albums, ...videos, ...spncasts];
    const matchedSong = allReleases.find(release => release.contentId === song.contentId);
    
    if (matchedSong) {
      song.ArtistName = matchedSong.ArtistName || song.ArtistName;
      song.SongName = matchedSong.SongName || song.SongName;
      song.albumName = matchedSong.albumName || song.albumName;
      song.producers = matchedSong.producers || song.producers;
      song.songwriters = matchedSong.songwriters || song.songwriters;
      song.recordLabels = matchedSong.recordLabels || song.recordLabels;
      song.VideoURL = matchedSong.VideoURL || song.VideoURL;
      song.ArtworkURL = matchedSong.ArtworkURL || song.ArtworkURL;
      return matchedSong;
    }
    return song;
  };

  const [comments, setComments] = useState([]);

  const handleDelete = async () => {
    if (!activeItemId) return;

    const { post, type, commentId, postId } = activeItemId;
    const db = getFirestore();
    let ref;

    if (type === 'post') {
      ref = doc(db, 'posts', post.postId);
}

    if (ref) {
      try {
        await deleteDoc(ref);
        if (type === 'post') {
          setPosts(prevPosts => prevPosts.filter(p => p.id !== post.postId));
        
        }
        setActiveItemId(null);
      } catch (error) {
        console.error("Error deleting the document:", error);
      }
    } else {
      console.error("Failed to build Firestore reference for deletion.");
    }
  };

const getCachedUserDoc = async (userId) => {
  let userDataCached = [];
  let userDataCachedNew = [];
  const now = new Date().getTime();

  if (!userDataCached || now - userDataCached.timestamp >= CACHE_DURATION) {
      
      const userDocRef = doc(firestore, 'users', userId);
      const userDocSnap = await getDoc(userDocRef);

      if (userDocSnap.exists()) {
          
          const freshUserData = userDocSnap.data();

          
          // Update userDataCached structure with fresh data and timestamp
          userDataCachedNew = {
              ...freshUserData, // overwrite with fresh data from Firestore
              profileImageUrl: freshUserData?.profileImageUrl,
              timestamp: now
          };


      } else {
          console.error(`[getCachedUserDoc] No document found in Firestore for userId: ${userId}`);
      }
  } else {
      
      userDataCachedNew = userDataCached;
  }

  return userDataCachedNew;
};

const scrollContainerRef = useRef(null);
const sentinelRef = useRef(null);

useEffect(() => {
  const handleIntersection = (entries) => {
    const entry = entries[0];
    if (entry.isIntersecting) {
      
      if (!loadingMore && !allPostsLoaded) {
        
        fetchPosts(true);
      } else {
        
      }
    }
  };

  const observer = new IntersectionObserver(handleIntersection, {
    root: scrollContainerRef.current,
    threshold: 1.0,
  });

  const observeSentinel = () => {
    if (sentinelRef.current) {
      
      observer.observe(sentinelRef.current);
    }
  }; 

  // Add a small delay before setting up the observer
  const timerId = setTimeout(observeSentinel, 100); // 100 milliseconds

  return () => {
    clearTimeout(timerId);
    if (sentinelRef.current) {
      
      observer.unobserve(sentinelRef.current);
    }
  };
}, [posts, allPostsLoaded, loadingMore]);



const handlePressProfile = (profile) => {
  const clonedProfile = JSON.parse(JSON.stringify(profile));
  const targetRoute = profile.userId === userId2 ? '/mydat/profile' : `/userprofile/${profile.username}`;
  navigate(targetRoute, { state: { profile: clonedProfile, fromURL: false } });
};



const fetchUserProfile = async (userId) => {
  try {
    const userDocRef = doc(firestore, 'users', userId);
    const userDoc = await getDoc(userDocRef);
    if (userDoc.exists()) {
      handlePressProfile(userDoc.data());
    } else {
      
    }
  } catch (error) {
    console.error('Error fetching user profile:', error);
  }
};

const fetchUserProfileByUserId = async (userId) => {
  if (!userId) {
    
    return;
  }

  


  try {
    fetchUserProfile(userId);

  } catch (error) {
    console.error('fetchUserProfileByUserId: Error fetching user by userId:', error);
  } finally {

    setIsLoading(false);
  }
};

  const renderOptions = () => {
    if (!activeItemId) return null;
    const { post, type } = activeItemId;
    const isOwner = (type === 'post' ? post.userId : type === 'comment' ? comments.find(c => c.commentId === post.id).userId : comments.find(c => c.replies.some(r => r.replyId === post.id)).replies.find(r => r.replyId === post.id).userId) === userId2;

    return (
      <div style={styles.optionsContainer} onClick={(e) => e.stopPropagation()}>
        <button style={{marginBottom: '4px'}} onClick={(e) => { e.stopPropagation(); handleShare(post); }}>
          Share
        </button>
        {isOwner && (
          <button onClick={(e) => { e.stopPropagation(); handleDelete(); }}>
            Delete
          </button>
        )}
        {!isOwner && (
          <button onClick={(e) => { e.stopPropagation(); handleReport(post); }}>
            Report
          </button>
        )}
      </div>
    );
  };

  const handleToggleOptions = (post, type, commentId, postId = null) => {
    const newItemActive = { post, type, commentId, postId };
    if (activeItemId && activeItemId.post.postId === post.postId && activeItemId.type === type) {
      setActiveItemId(null);
    } else {
      setActiveItemId(newItemActive);
    }
  };



  const handleMediaUriTap = async (mediaUri, mediaType) => {
    if (mediaType === 'video') {
      setCurrentVideoUri(mediaUri);
      setModalVisible(true);
    } else {
      setModalVisible(true);
      setCurrentImage(mediaUri);
    }
  };

  const handleShare = (post) => {
    setPostToShare(post);
    setSharePostActive(true);
  };

  const handleReport = async (post) => {
    const db = getFirestore();
    const reportRef = doc(collection(db, 'reports'));

    const reportData = {
      reportId: reportRef.id,
      postId: post.id,
      text: post.text || '',
      imageUri: post.imageUri || '',
      userId: post.userId,
      username: post.username,
      name: post.name,
      profileImageUrl: post.profileImageUrl,
      createdAt: serverTimestamp(),
      reportedAt: serverTimestamp(),
    };

    try {
      await setDoc(reportRef, reportData);
      setReportComplete(true);
    } catch (error) {
      console.error("Error reporting the post: ", error);
    }
  };

  const formatTimeAgo = (firestoreTimestamp) => {

    const postDate = firestoreTimestamp.toDate ? firestoreTimestamp.toDate() : new Date(firestoreTimestamp.seconds * 1000);
  
    const now = new Date();
    const differenceInSeconds = Math.floor((now - postDate) / 1000);
    const differenceInMinutes = Math.floor(differenceInSeconds / 60);
    const differenceInHours = Math.floor(differenceInMinutes / 60);
    const differenceInDays = Math.floor(differenceInHours / 24);
  
    let formattedTimeAgo;
    if (differenceInDays > 0) {
      formattedTimeAgo = `${differenceInDays}d`;
    } else if (differenceInHours > 0) {
      formattedTimeAgo = `${differenceInHours}h`;
    } else if (differenceInMinutes > 0) {
      formattedTimeAgo = `${differenceInMinutes}m`;
    } else {
      formattedTimeAgo = `${differenceInSeconds}s`;
    }
  
    
    return formattedTimeAgo;
  }


  const handleVideoError = async (item) => {
    const functions = getFunctions();
    console.error(`Video failed to load for postId: ${item.postId}`);
  
    // Call the Cloud Function to process the video
    try {
      const convertAndFixVideo = httpsCallable(functions, 'convertFixVideo');
  
      const result = await convertAndFixVideo({
        postId: item.postId,  // Post ID to update after conversion
        videoUri: item.imageUri,  // Original video URL (that is failing)
        userId: item.userId  // User ID for organizing uploads
      });
  

  
    } catch (error) {
      console.error('Error triggering video conversion Cloud Function:', error);
    }
  };
  

  
 
  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer} data-scroll-container ref={scrollContainerRef}>
        <ul style={{ ...styles.scrollContainer2 }}>
          {posts.map((item) => (
            <li key={item.postId} onClick={() => handlePressPost(item)} style={styles.postContainer}>
              
              <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <div style={styles.contentRow}>
                  
                  <ProfilesDisplaySharePost userProfile={item} onPress={() => fetchUserProfileByUserId(item.userId)} />
                  
                  <div style={styles.timeAgoStyle}>{formatTimeAgo(item.createdAt)}</div>
                  <button style={styles.moreInfoButton} onClick={(e) => {
    e.stopPropagation();
    
    handleToggleOptions(item, 'post');
  }}>
                    <img src={ellipsis} style={{ position: 'relative', bottom: '5px', width: '20px', height: '20px', backgroundColor: 'transparent', zIndex: '50000', pointerEvents: 'auto' }}></img>
                  </button>
                  {activeItemId && activeItemId.post.postId === item.postId && activeItemId.type === 'post' && renderOptions()}
                </div>
              </div>
              {item?.media ? (
                <div onClick={(e) => { e.stopPropagation(); handleMove(item.media); }} style={styles.imageContainer}>
    <img src={item.media.ArtworkURL} alt="Media" style={styles.postImage} />
  </div>
) : item.imageUri ? (
  <div style={item.text ? styles.mediaContainer : { ...styles.mediaContainer, ...styles.additionalMargin }}>
    {item.mediaType === 'video' ? (
      <div style={styles.mediaContainer2}>
        <video
          src={item.imageUri}
          style={styles.mediaThumbnail2}
          controls

          onClick={(e) => e.stopPropagation()}
          onError={(e) => handleVideoError(item)}
        />
      </div>
    ) : (
      <img src={item.imageUri} alt="Media" style={styles.postImage} />
    )}
  </div>
) : null}


{item.text && (
  <p
    style={{
      whiteSpace: 'pre-wrap',  // This preserves both spaces and line breaks.
      ...(item.mediaType === 'video'
        ? styles.postText2
        : item.imageUri
        ? styles.postText
        : styles.postTextNoImage)
    }}
  >
    {item.username === "spndatnews" ? (
      <>
        {item.text.substring(0, 500)}
        {item.text.length > 500 && "..."}
      </>
    ) : (
      item.text.split(/(\@\w+)/g).map((part, index) => {
        const tagsToUse =
          item.taggedUsers && item.taggedUsers.length > 0
            ? item.taggedUsers
            : item.tags;

        if (part.startsWith('@') && tagsToUse) {
          const userTag = tagsToUse.find(
            (tag) => `@${tag.username}` === part
          );
          if (userTag) {
            return (
              <span
                style={styles.tagContainer}
                key={index}
                onClick={() => fetchUserProfileByUserId(userTag.userId)}
              >
                <span style={{ color: '#62fbfa' }}>{part} </span>
              </span>
            );
          }
        }

        return <span key={index}>{part} </span>; // Ensure proper spacing
      })
    )}
  </p>
)}




              {item.profile && (
                <ProfilesDisplaySharePost userProfile={item.profile} onPress={() => fetchUserProfileByUserId(item.profile.userId)} />
              )}
              {item.originalPost && (
                <div onClick={() => handlePressOriginalPost(item.originalPost)} style={styles.postContainer2}>
                  <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={styles.contentRow}>
                      <ProfilesDisplaySharePost userProfile={item.originalPost} onPress={() => fetchUserProfileByUserId(item.originalPost.userId)} />
                      <div style={styles.timeAgoStyle}>{formatTimeAgo(item.originalPost.createdAt)}</div>
                    </div>
                  </div>
                  {item?.originalPost?.media ? (
  <div onClick={(e) => { e.stopPropagation(); handleMove(item.originalPost.media); }} style={styles.imageContainer}>
    <img src={item.originalPost.media.ArtworkURL} alt="Media" style={styles.postImage} />
  </div>
) : item.originalPost.imageUri ? (
  <div style={item.originalPost.text ? styles.mediaContainer : { ...styles.mediaContainer, ...styles.additionalMargin }}>
    {item.originalPost.mediaType === 'video' ? (
      <div style={styles.mediaContainer2}>
        <video
          src={item.originalPost.imageUri}
          style={styles.mediaThumbnail2}
          controls
          onClick={(e) => e.stopPropagation()}
          onError={(e) => handleVideoError(item)}
        />
      </div>
    ) : (
      <img src={item.originalPost.imageUri} alt="Media" style={styles.postImage} />
    )}
  </div>
) : null}

                  {item?.originalPost?.profile && (
                    <ProfilesDisplaySharePost userProfile={item?.originalPost?.profile} onPress={() => fetchUserProfileByUserId(item?.originalPost?.profile?.userId)} />
                  )}
{item.originalPost.text && typeof item.originalPost.text === 'string' && (
  <p
    style={{
      whiteSpace: 'pre-wrap',  // This preserves both spaces and line breaks.
      ...(item.originalPost.imageUri
        ? styles.postText
        : styles.postTextNoImage)
    }}
  >
    {item.originalPost.username === "spndatnews" ? (
      <>
        {item.originalPost.text.substring(0, 500)}
        {item.originalPost.text.length > 500 && "..."}
      </>
    ) : (
      item.originalPost.text
    )}
  </p>
)}


                </div>
              )}
              <div style={styles.iconsContainer}>
              <button style={styles.iconButtonLike} onClick={(e) => { e.stopPropagation(); handleLikePress(item.postId); }}>
                  <div style={styles.likeButtonContainer}>
                  <img src={likes[item.postId] ? LikeIconActive : LikeIcon} alt="Like" style={styles.iconImage} />
                    <span style={styles.likeCount}>{item.likeCount || 0}</span>
                  </div>
                </button>
                <button onClick={() => handlePressPostComment(item)} style={styles.iconButton}>
                  <div style={styles.likeButtonContainer}>
                  <img src={CommentIcon} alt="Comment" style={styles.iconImage} />
                    <span style={styles.likeCount}>{item.commentCount || 0}</span>
                  </div>
                </button>
                {!item.originalPost && (
                  <button style={styles.iconButton} onClick={(e) => { e.stopPropagation(); handleRepost(item); }}>
                    <div style={styles.likeButtonContainer}>
                      <img src={
                        reposts[item.id] || (item.originalPost && reposts[item.originalPost.id])
                          ? RepostIconActive
                          : RepostIcon
                      } alt="Repost" style={styles.iconImage} />
                      <span style={styles.likeCount}>{item.repostCount || 0}</span>
                    </div>
                  </button>
                )}
              </div>
            </li>
            
          ))}
                        <div ref={sentinelRef} style={{ backgroundColor: 'transparent', height: '50px' }}></div>
                        <div style={styles.extraSpace}></div>
        </ul>
        {reportComplete && (
          <div style={styles.fullScreenOverlay} onClick={() => setReportComplete(false)}>
            <img src="/assets/DATBG.png" alt="Background" style={styles.fullScreenImage} />
            <span style={styles.overlayText}>Report Successful</span>
          </div>
        )}
        {showActivityIndicator && (
          <div style={styles.activityIndicator}></div>
        )}
      </div>
      <Modal
      isOpen={sharePostActive}
      onRequestClose={() => setSharePostActive(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 10000,
        },
        content: {
          top: '30%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          height: '260px',
          width: '80%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          padding: '20px',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={styles.sharingPost}>
      <ProfilesDisplayShareDATpost/>
      </div>
      <div style={styles.cancelContainer}>
      <button style={styles.cancelButton} onClick={() => setSharePostActive(false)}>Cancel</button>
      </div>
    </Modal>
    <Modal
      isOpen={showSharePostPopup}
      onRequestClose={() => setShowSharePostPopup(false)}
      style={{
        overlay: {
          backgroundColor: 'rgba(0, 0, 0, 0.85)',
          zIndex: 10000,
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          height: '400px',
          width: '95%',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'transparent',
          padding: '20px',
          border: 'none',
          borderRadius: '8px',
        },
      }}
    >
      <div style={styles.sharingPost}>
      <SharePostPopup/>
      </div>
      <div style={styles.cancelContainer}>
      <button style={styles.cancelButton} onClick={() => setShowSharePostPopup(false)}>Cancel</button>
      </div>
    </Modal>
    </div>
  );
  
};

const styles = {
    postContainer: {
      padding: '7px',
      backgroundColor: "rgba(0,0,0,0.5)",
      borderRadius: '8px',
      border: '1px solid rgba(23, 195, 250, 0.5)', // Use shorthand property for border
      marginLeft: '10px',
      marginRight: '10px',
      zIndex: '500',
      position: 'relative',
      cursor: 'pointer',
    },
    sharingPost: {
width: '100%',
height: '100%',
display: 'flex',
alignSelf: 'center',
justifyContent: 'center',
    },
    cancelContainer: {
display: 'flex',
width: '100%',
height: '100%',
justifyContent: 'center',
alignSelf: 'center',
    },
    cancelButton: {
      padding: '10px 20px',
      marginBottom: '80px',
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
      margin: '0 auto',
      position: 'absolute',
      bottom: '10px',
      backgroundColor: 'transparent',
      color: '#62fbfa',
      border: 'none',
      fontSize: '20px',
      borderRadius: '4px',
      cursor: 'pointer',
    },
    extraSpace: {
      height: '300px',
        },
  
  activityIndicator: {
    position: 'absolute',
    left: '50%',
    top: '50%',
    transform: 'translate(-50%, -50%)'
  },
  controlButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '10px'
  },
  iconButton: {
    paddingLeft: '20px',
    marginLeft: '-20px',
    paddingRight: '20px',
    marginRight: '-20px',
    marginBottom: '-10px',
    paddingBottom: '10px',
    paddingTop: '8px',
    marginTop: '-8px',
    backgroundColor: 'transparent',
    border: 'none',

  },
  iconButtonLike: {
    paddingLeft: '20px',
    marginLeft: '-20px',
    paddingRight: '20px',
    marginRight: '-20px',
    marginBottom: '-10px',
    paddingBottom: '10px',
    paddingTop: '8px',
    marginTop: '-8px',
    backgroundColor: 'transparent',
    border: 'none',
    // Any other styles you want to apply to the touchable opacity
  },
  
  likeButtonContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'flex-start',
    backgroundColor: 'transparent',
    cursor: 'pointer',
  },
  iconImage: {
    width: '16px',
    height: '16px',
    marginRight: '5px',
    backgroundColor: 'transparent',
  },
  likeCount: {
    color: 'white',
    fontSize: '16px',
  },
  postContainer2: {
    padding: '7px',
    backgroundColor: "rgba(0,0,0,0.5)",
    borderWidth: '1px',
    borderRadius: '8px',
    border: '1px solid rgba(23, 195, 250, 0.5)',
    marginLeft: '10px',
    marginRight: '10px',
    marginBottom: '20px',
    cursor: 'pointer',
  },
  postText: {
    marginTop: '20px',
    marginLeft: '15px',
    marginBottom: '18px',
    marginRight: '20px',
    color: 'white',
    fontSize: '16px',
    fontFamily: 'brhendrix',
  },
  postText2: {
    marginTop: '20px',
    marginLeft: '20px',
    marginRight: '20px',
    marginBottom: '15px',
    color: 'white',
    fontSize: '16px',
    fontFamily: 'brhendrix',
  },
postTextNoImage: {
  marginTop: '-8px',
  marginLeft: '30px',
  marginBottom: '18px',
  marginRight: '20px',
  color: 'white',
  fontSize: '18px',
  fontFamily: 'brhendrix',
},
  postImage: {
    width: '100%',
    height: '300px',
    alignSelf: 'center',
    marginTop: '10px',
    marginBottom: '20px',
    objectFit: 'contain',
  },
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
    position: 'relative',
    overflowY: 'scroll',
  },
  scrollContainer2: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 2,
    listStyleType: 'none',
    padding: 0,
    overflowX: 'hidden',
    overflowY: 'scroll', // Ensure vertical scrolling
  },
  scrollContainer: {
    position: 'relative',
    width: '100%',
    height: '100%',
    zIndex: 3,
    marginBottom: '180px',
    overflowY: 'scroll', // Ensure vertical scrolling
  },
  iconsContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-evenly',
    alignItems: 'center',
    marginBottom: '5px',
  },
  moreInfoButton: {
    marginLeft: 'auto',
    marginBottom: '20px',
    width: '50px',
    pointerEvents: 'auto',
    height: '100%',
    padding: '10px',
    border: 'none',
    backgroundColor: 'transparent',
    zIndex: '50000',
    position: 'relative',
    cursor: 'pointer',
  },
  
  contentRow: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    flex: 1,
  },
  timeAgoStyle: {
    color: 'white',
    fontSize: '14px',
    flexShrink: 1,
    right: 50,
    position: 'relative',
    marginBottom: '15px',
    justifyContent: 'center',
    alignSelf: 'center',
    paddingHorizontal: '5px',
    paddingBottom: '21px',
  },
  buttonTouchableArea: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    zIndex: 1,
  },
  optionsContainer: {
    position: 'absolute',
    right: 20,
    display: 'flex',
    flexDirection: 'column',
    top: 25,
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    borderRadius: '5px',
    padding: '5px',
    marginRight: '5%',
    zIndex: 10000,
  },
  fullscreenVideo: {
    width: '100%',
    height: '100%',
    backgroundColor: '#000',
  },
  modal: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.8)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  closeButton: {
    position: 'absolute',
    top: '20px',
    right: '20px',
    padding: '10px',
    backgroundColor: 'white',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
  tagContainer: {
    display: 'inline',
    cursor: 'pointer',
  },
  playIcon: {
    position: 'absolute',
    width: '68px',
    height: '80px',
    alignSelf: 'center',
  },
  fullScreenOverlay: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10000,
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  overlayText: {
    color: 'white',
    fontSize: '20px',
    fontWeight: 'bold',
    position: 'absolute',
    textAlign: 'center',
    width: '100%',
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '10px',
    overflow: 'hidden',
    borderRadius: '10px',
    marginBottom: '20px',
  },
  mediaContainer: {
    display: 'flex', 
    justifyContent: 'center',
    alignItems: 'center',
    overflow: 'hidden',
    borderRadius: '10px',
    objectFit: 'contain',
    zIndex: 500,
  },
  mediaContainer2: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    borderRadius: '20px',
    display: 'flex',
    marginBottom: '20px',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'black',
    zIndex: 500,
    
  },
  mediaThumbnail2: {
    width: '100%',
    height: '120%',
    objectFit: 'cover',
  },
};



export default Social;
