import React, { useContext } from 'react';
import { Routes, Route, useNavigate } from 'react-router-dom';
import { AppContext } from './AppContext';
import LoginScreen from './LoginScreen'; // Importing the login screen
import CategoriesScreen2 from './CategoriesScreen2';
import LibraryCategoryScreen from './LibraryCategoryScreen';
import ArtistsScreenMyContent from './ArtistsScreenMyContent';
import ArtistsMediaListScreen from './ArtistsMediaListScreen';
import TheFollowersPage from './TheFollowersPage';
import ArtistsScreenSettings from './ArtistsScreenSettings';
import PasswordChange from './PasswordChange';
import Multifactor from './Multifactor';
import Report from './Report';
import EarningsPage from './EarningsPage';
import CASHDATEmail from './CASHDATEmail';
import AnalyticsPage from './AnalyticsPage';
import SMPPage from './SMPPage';
import SupportPage from './SupportPage';
import Terms from './Terms';
import DeactivateAccount from './DeactivateAccount';
import PersonalInfo from './PersonalInfo';
import ReleaseMusic from './ReleaseMusic';
import ReleaseFilm from './ReleaseFilm';
import ReleaseTrailer from './ReleaseTrailer';
import MySubmissions from './MySubmissions';
import Submissions from './Submissions';
import VoiceDropPage from './VoiceDropPage';
import ScreenSKNPage from './ScreenSKNPage';
import ReleaseMusicVideos from './ReleaseMusicVideos';
import ReleaseSPNCast from './ReleaseSPNCast';
import ReleaseBeats from './ReleaseBeats';
import RemoveReleasesPage from './RemoveReleasesPage';
import MYDATprofile from './MYDATprofile';

const MYDAT = ({ isMFAEnabled, setIsMFAEnabled, setShowMFAPrompt, onLogout, onMoveMP, onProfilePressed }) => {
  const { userId2 } = useContext(AppContext); // Get userId2 from AppContext
  const navigate = useNavigate();

  if (userId2 === 'guest') {
    // Render "Log In" option if user is a guest
    return (
      <div style={styles.fullScreen}>
        <div style={styles.centeredContainer}>
          <button style={styles.loginButton} onClick={() => navigate('/login')}>
            Log In
          </button>
        </div>
      </div>
    );
  }

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <Routes>
          <Route index element={<CategoriesScreen2 />} />
          <Route path="/categories" element={<CategoriesScreen2 />} />
          <Route
            path="/profile"
            element={
              <MYDATprofile category="Profile" onProfilePress={onProfilePressed} onMoveMP={onMoveMP} />
            }
          />
          <Route path="/mycontent/*" element={<ArtistsStackNavigator category="MyContent" onMoveMP={onMoveMP} />} />
          <Route path="/Submissions" element={<Submissions />} />
          <Route
            path="/followers"
            element={
              <TheFollowersPage
                onProfilePress={onProfilePressed}
                category="Followers"
                onMoveMP={onMoveMP}
              />
            }
          />
          <Route
            path="/settings/*"
            element={
              <SettingsStackNavigator
                isMFAEnabled={isMFAEnabled}
                setIsMFAEnabled={setIsMFAEnabled}
                setShowMFAPrompt={setShowMFAPrompt}
                onMoveMP={onMoveMP}
              />
            }
          />
          <Route path="/earnings" element={<EarningsPage category="Earnings" onMoveMP={onMoveMP} />} />
          <Route
            path="/analytics"
            element={<AnalyticsPage onProfilePress={onProfilePressed} onMoveMP={onMoveMP} />}
          />
          <Route path="/smp" element={<SMPPage category="SMP" onMoveMP={onMoveMP} />} />
          <Route path="/terms" element={<Terms category="Terms" onMoveMP={onMoveMP} />} />
        </Routes>
      </div>
    </div>
  );
};

const ArtistsStackNavigator = ({ onMoveMP }) => {
  return (
    <Routes>
      <Route index element={<ArtistsScreenMyContent />} />
      <Route path="/" element={<ArtistsScreenMyContent />} />
      <Route path="/artistsmedialist" element={<ArtistsMediaListScreen onMoveMP={onMoveMP} />} />
      <Route path="/releasemusic" element={<ReleaseMusic />} />
      <Route path="/releasefilm" element={<ReleaseFilm />} />
      <Route path="/releasetrailer" element={<ReleaseTrailer />} />
      <Route path="/mysubmissions" element={<MySubmissions />} />
      <Route path="/releasemusicvideos" element={<ReleaseMusicVideos />} />
      <Route path="/releasespncast" element={<ReleaseSPNCast />} />
      <Route path="/releasebeats" element={<ReleaseBeats />} />
      <Route path="/voicedroppage" element={<VoiceDropPage />} />
      <Route path="/screensknpage" element={<ScreenSKNPage />} />
      <Route path="/removereleasespage" element={<RemoveReleasesPage />} />
    </Routes>
  );
};

const SettingsStackNavigator = ({ isMFAEnabled, setIsMFAEnabled, setShowMFAPrompt, onMoveMP }) => {
  return (
    <Routes>
      <Route index element={<ArtistsScreenSettings />} />
      <Route path="/" element={<ArtistsScreenSettings />} />
      <Route path="/passwordchange" element={<PasswordChange onMoveMP={onMoveMP} />} />
      <Route
        path="/multifactor"
        element={
          <Multifactor
            isMFAEnabled={isMFAEnabled}
            setIsMFAEnabled={setIsMFAEnabled}
            setShowMFAPrompt={setShowMFAPrompt}
            onMoveMP={onMoveMP}
          />
        }
      />
      <Route path="/personalinfo" element={<PersonalInfo onMoveMP={onMoveMP} />} />
      <Route path="/report" element={<Report onMoveMP={onMoveMP} />} />
      <Route path="/cashdatemail" element={<CASHDATEmail onMoveMP={onMoveMP} />} />
      <Route path="/support" element={<SupportPage category="Support" onMoveMP={onMoveMP} />} />
      <Route path="/terms" element={<Terms category="Terms" onMoveMP={onMoveMP} />} />
      <Route path="/deactivateaccount" element={<DeactivateAccount category="DeactivateAccount" onMoveMP={onMoveMP} />} />
    </Routes>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    overflow: 'hidden',
  },
  scrollContainer: {
    flex: 1,
    overflowY: 'auto',
    overflowX: 'hidden',
    width: '100%',
    height: '100%',
    position: 'relative',
  },
  centeredContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    height: '100%',
    bottom: '100px',
    left: '50%', // Center horizontally
    transform: 'translateX(-50%)', // Offset by 50% of its own width
  },  
  loginButton: {
    padding: '10px 20px',
    fontSize: '18px',
    backgroundColor: '#62fbfa',
    color: '#000',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  },
};

export default MYDAT;
