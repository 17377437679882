import React from 'react';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {
  const navigate = useNavigate();
   // Debug log

  const DISPLAY_TEXT = `PRIVACY POLICY

SPNDAT INC. ("we" or "us") is committed to protecting the privacy of our users ("User" or "you"). This Privacy Policy explains how we collect, use, disclose, and safeguard your information when you use our social media, music, and video app (the "App"). Please read this Privacy Policy carefully. If you do not agree with the terms of this Privacy Policy, do not access or use the App.

1. Information We Collect
1.1. Personal Information: We may collect personal information such as your name, email address, and demographic information (such as your age, gender, and location) when you register for an account or use certain features of the App.
1.2. Usage Information: We may collect information about your use of the App, including the content you view or interact with, the features you use, and the actions you take.
1.3. Device Information: We may collect information about the device you use to access the App, including the hardware model, operating system and version, unique device identifiers, and mobile network information.

2. How We Use Your Information
2.1. We may use the information we collect to:
Provide and improve the App;
Customize your experience;
Communicate with you;
Analyze usage trends and preferences;
Detect, prevent, and address technical issues;
Comply with legal requirements.

3. How We Share Your Information
3.1. We may share your information with third parties for the following purposes:
Service providers: We may share your information with third-party service providers who help us provide and improve the App.
Business transfers: If we are involved in a merger, acquisition, or sale of all or a portion of our assets, your information may be transferred as part of that transaction.
Legal purposes: We may disclose your information in response to a subpoena, court order, or other legal process.

4. Your Choices
4.1. You may choose not to provide certain information, but this may limit your ability to use certain features of the App.
4.2. You can update or delete your account information at any time by contacting us at support@spndat.com.

5. Security
5.1. We take reasonable measures to protect your information from unauthorized access, use, or disclosure.

6. Changes to this Privacy Policy
6.1. We may update this Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.

7. Contact Us
7.1. If you have any questions about this Privacy Policy, please contact us at support@spndat.com.
write to us at: SPNDAT Inc., 2025 S McCall Road Englewood Florida 34223
SPNDAT Inc. is the data controller of personal data processed under this Policy.

© SPNDAT INC.

`;

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <p style={styles.fullScreenText}>{DISPLAY_TEXT}</p>
      </div>
      <button onClick={() => navigate('/login')} style={styles.backButton}>
        Back to Login
      </button>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'flex-start', // Align items to the top
    alignItems: 'center',
    backgroundColor: '#0e061e',
    position: 'relative',
    overflow: 'hidden',
  },
  scrollContainer: {
    width: '100%',
    maxWidth: '480px',
    flex: 1, // Take up remaining space
    overflowY: 'auto',
    height: '100%',
    boxSizing: 'border-box',
    marginBottom: '150px',
  },
  fullScreenText: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
    textAlign: 'left',
    fontFamily: 'brhendrix',
    letterSpacing: '-0.4px',
    whiteSpace: 'pre-wrap',
  },
  backButton: {
    position: 'fixed',
    bottom: '100px',
    padding: '10px 20px',
    fontSize: '16px',
    color: '#62fbfa',
    backgroundColor: 'black',
    border: 'none',
    borderRadius: '5px',
    cursor: 'pointer',
  }
};

export default PrivacyPolicy;
