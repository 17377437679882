import React, { useState, useEffect } from 'react';
import { getAuth, multiFactor } from 'firebase/auth';

const Multifactor = ({isMFAEnabled, setIsMFAEnabled, setShowMFAPrompt}) => {
  const auth = getAuth();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const checkMFAStatus = async () => {
      const user = auth.currentUser;

      if (user) {
        const enrolledFactors = multiFactor(user).enrolledFactors;
        setIsMFAEnabled(enrolledFactors.length > 0);
      } else {
        console.error("No user is logged in");
      }

      setLoading(false);
    };

    checkMFAStatus();
  }, [auth]);

  const enableMFA = async () => {
    try {

      setShowMFAPrompt(true);
    } catch (error) {
      console.error("Error enabling MFA:", error);
      alert("Failed to enable Multi-Factor Authentication.");
    }
  };

  const disableMFA = async () => {
    try {
      const currentUser = auth.currentUser;
      if (!currentUser) {
        alert("No authenticated user found.");
        return;
      }
  
      // Get enrolled factors
      const enrolledFactors = multiFactor(currentUser).enrolledFactors;
  
      if (enrolledFactors.length === 0) {
        alert("No MFA factors are currently enrolled.");
        return;
      }
  
      // Unenroll the first factor (assuming one factor for simplicity)
      const factorToUnenroll = enrolledFactors[0];
      console.log("Unenrolling factor:", factorToUnenroll);
  
      // Call unenroll method on the multiFactor object
      await multiFactor(currentUser).unenroll(factorToUnenroll);
  
      console.log("Successfully unenrolled MFA factor.");
      setIsMFAEnabled(false); // Update the state
      alert("MFA disabled successfully!");
    } catch (error) {
      console.error("Error disabling MFA:", error);
      alert("Failed to disable MFA.");
    }
  };
  
  
  

  if (loading) {
    return <div style={styles.fullScreen}>Loading...</div>;
  }

  return (
    <div style={styles.fullScreen}>
      <div style={styles.scrollContainer}>
        <h2 style={styles.title}>Multi-Factor Authentication</h2>
        {isMFAEnabled ? (
          <>
            <p style={styles.text}>MFA is currently enabled for your account.</p>
            <button style={styles.button} onClick={disableMFA}>
              <span style={styles.buttonText}>Disable MFA</span>
            </button>
          </>
        ) : (
          <>
            <p style={styles.text}>MFA is currently disabled for your account.</p>
            <button style={styles.button} onClick={enableMFA}>
              <span style={styles.buttonText}>Enable MFA</span>
            </button>
          </>
        )}
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    overflow: 'hidden',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 10,
  },
  scrollContainer: {
    width: '100%',
    maxWidth: '500px',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '10vh',
    paddingBottom: '10vh',
    overflowY: 'auto',
    boxSizing: 'border-box',
    zIndex: 10,
  },
  title: {
    color: '#62fbfa',
    fontSize: '24px',
    marginBottom: '20px',
  },
  text: {
    color: 'white',
    fontSize: '18px',
    marginBottom: '20px',
    textAlign: 'center',
  },
  button: {
    borderRadius: '5px',
    padding: '10px',
    justifyContent: 'center',
    alignItems: 'center',
    width: '90%',
    marginBottom: '10px',
    backgroundColor: '#62fbfa',
    border: 'none',
  },
  buttonText: {
    color: 'black',
    fontSize: '18px',
    textAlign: 'center',
  },
};

export default Multifactor;
