import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useNavigate, useLocation } from 'react-router-dom';
import { PhoneMultiFactorGenerator, getAuth, RecaptchaVerifier, onAuthStateChanged, multiFactor, PhoneAuthProvider } from 'firebase/auth';
import { AppProvider } from './AppContext';
import { CacheProvider } from './CacheContext';
import LoginScreen from './LoginScreen';
import SignupScreen from './SignupScreen';
import AppWrapped from './AppWrapped';
import PrivacyPolicy from './PrivacyPolicy';
import MYDATprofileUser from './MYDATprofileUser';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { auth } from './firebase';
import './globalStyles.css';
import './index.css';


const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [clientSecret, setClientSecret] = useState(null);

  const verifierRef = React.useRef(null);

  const [navigateRefresh, setNavigateRefresh] = useState(null);


  const navigate = useNavigate();
  const location = useLocation();
  
  const [isMFAEnabled, setIsMFAEnabled] = useState(false);
  const [showMFAPrompt, setShowMFAPrompt] = useState(false);
  const [showPhoneNumberInput, setShowPhoneNumberInput] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [showCodeInput, setShowCodeInput] = useState(false);
  const [verificationId, setVerificationId] = useState(null);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false); // Set loading to false once the auth state is determined
    });

    // Set a timeout to avoid long loading times
    const timeoutId = setTimeout(() => {
      setLoading(false);
    }, 10000); // Adjust the timeout duration as needed

    return () => {
      unsubscribe();
      clearTimeout(timeoutId); // Clear the timeout if the component unmounts
    };
  }, []);

  useEffect(() => {
    if (
      isLoggedIn &&
      !showMFAPrompt &&
      !showPhoneNumberInput &&
      !showCodeInput &&
      (location.pathname === '/login' || location.pathname === '/signup')
    ) {
      navigate('/music');
    }
  }, [isLoggedIn, showMFAPrompt]);

  useEffect(() => {
    if (!window.recaptchaVerifier) {
      try {
        window.recaptchaVerifier = new RecaptchaVerifier(
          auth, // Place auth as the first parameter
          "recaptcha-container", // ID of the container element
          {
            size: "invisible", // Or "normal" for visible reCAPTCHA
            callback: (response) => {
              console.log("reCAPTCHA solved:", response);
            },
            "expired-callback": () => {
              console.warn("reCAPTCHA expired. Please try again.");
            },
          }
        );
  
        // Render the reCAPTCHA widget
        window.recaptchaVerifier.render().then((widgetId) => {
          console.log("reCAPTCHA rendered with ID:", widgetId);
        });
      } catch (error) {
        console.error("Error initializing reCAPTCHA:", error);
      }
    }
  }, [showMFAPrompt]);
  
  
  const validatePhoneNumber = (phoneNumber) => {
    const phoneRegex = /^[+]?[1-9]\d{1,14}$/; // E.164 format
    return phoneRegex.test(phoneNumber);
  };
  
  

  const sendVerificationCode = async () => {

    if (!validatePhoneNumber(phoneNumber)) {
      alert("Invalid phone number format. Please enter a valid phone number.");
      return;
    }

    try {
      console.log("Starting sendVerificationCode...");
  
      if (!window.recaptchaVerifier) {
        console.error("RecaptchaVerifier not initialized.");
        return;
      }
  
      // Ensure the user is authenticated
      const currentUser = auth.currentUser;
      if (!currentUser) {
        alert("No authenticated user found. Please sign in first.");
        return;
      }
  
      console.log("Current User:", currentUser);
  
      // Get the Multi-Factor Session
      console.log("Getting Multi-Factor Session...");
      const multiFactorSession = await multiFactor(currentUser).getSession();
      console.log("Multi-Factor Session:", multiFactorSession);
  
      // Send the SMS verification code
      console.log("Sending verification code to:", phoneNumber);
      const phoneAuthProvider = new PhoneAuthProvider(auth);
  
      const verificationId = await phoneAuthProvider.verifyPhoneNumber(
        {
          phoneNumber,
          session: multiFactorSession,
        },
        window.recaptchaVerifier // Use the initialized reCAPTCHA verifier
      );
  
      console.log("Verification ID received:", verificationId);
  
      // Save the verification ID for later use
      setVerificationId(verificationId);
  
      alert("Verification code sent to your phone.");
    } catch (error) {
      console.error("Error sending verification code:", error);
      alert(`Error: ${error.message}`);
    }
  };
  
  

  const activateMFA = async () => {
    try {
      if (!verificationId || !verificationCode) {
        alert('Please enter the verification code.');
        return;
      }
  
      // Create a PhoneAuthCredential
      const phoneAuthCredential = PhoneAuthProvider.credential(verificationId, verificationCode);
  
      // Convert to a MultiFactorAssertion
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(phoneAuthCredential);
  
      // Enroll the second factor
      await multiFactor(auth.currentUser).enroll(multiFactorAssertion, 'My Phone Number');
  
      alert('MFA enabled successfully!');
      setShowCodeInput(false);
    } catch (error) { 
      console.error('Error enabling MFA:', error.message);
      alert('Failed to enable MFA. Please check the verification code and try again.');
    }
  }; 
  

    const handleButtonClick = () => {

    };


  const loadingStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    backgroundColor: '#0e061e',
    color: 'white',
    fontSize: '24px',
  };

  if (loading) {
    return (
      <div style={loadingStyles}>
        <div>Loading...</div>
      </div>
    );
  }

  return (
    <AppProvider>
      <CacheProvider>
        <div className="app-container">
          <div id="recaptcha-container"></div>
  
          {isLoggedIn && (
            <>
              <AppWrapped
                clientSecret={clientSecret}
                setClientSecret={setClientSecret}
                isMFAEnabled={isMFAEnabled}
                setIsMFAEnabled={setIsMFAEnabled}
                setShowMFAPrompt={setShowMFAPrompt}
                setNavigateRefreshHandler={setNavigateRefresh}
                user={user}
              />
              {/* MFA Prompts */}
              {showMFAPrompt && (
                <div style={styles.overlay}>
                  <div style={styles.mfaPrompt}>
                    <h2 style={styles.promptText}>
                      Enable Multi-Factor Authentication for Added Security
                    </h2>
                    <button
                      style={styles.button}
                      onClick={() => {
                        setShowMFAPrompt(false);
                        setShowPhoneNumberInput(true);
                      }}
                    >
                      Enable MFA
                    </button>
                    <button
                      style={styles.secondaryButton}
                      onClick={() => {
                        setShowMFAPrompt(false);
                        handleButtonClick();
                        setIsLoggedIn(true);
                      }}
                    >
                      Skip for Now
                    </button>
                  </div>
                </div>
              )}
              {showPhoneNumberInput && (
                <div style={styles.overlay}>
                  <div style={styles.mfaPrompt}>
                    <h2 style={styles.promptText}>Enter Your Phone Number</h2>
                    <input
                      type="text"
                      placeholder="Phone Number"
                      style={styles.input}
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                    />
                    <button
                      style={styles.button}
                      onClick={() => {
                        setShowPhoneNumberInput(false);
                        setShowCodeInput(true);
                        sendVerificationCode();
                      }}
                    >
                      Next
                    </button>
                    <button
                      style={styles.secondaryButton}
                      onClick={() => setShowPhoneNumberInput(false)}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
              {showCodeInput && (
                <div style={styles.overlay}>
                  <div style={styles.mfaPrompt}>
                    <h2 style={styles.promptText}>Enter Verification Code</h2>
                    <input
                      type="text"
                      placeholder="Verification Code"
                      style={styles.input}
                      value={verificationCode}
                      onChange={(e) => setVerificationCode(e.target.value)}
                    />
                    <button style={styles.button} onClick={activateMFA}>
                      Activate MFA
                    </button>
                    <button
                      style={styles.secondaryButton}
                      onClick={() => {
                        setShowCodeInput(false);
                      }}
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </CacheProvider>
    </AppProvider>
  );
  
  
};

const styles = {
  overlay: {
    position: 'fixed',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.7)',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    zIndex: 1000,
  },
  mfaPrompt: {
    backgroundColor: 'rgba(39, 18, 77, 0.9)',
    padding: '20px',
    borderRadius: '10px',
    textAlign: 'center',
    width: '80%',
    maxWidth: '400px',
  },
  promptText: {
    color: '#FFF',
    marginBottom: '16px',
    fontSize: '18px',
  },
  input: {
    width: '100%',
    padding: '10px',
    marginBottom: '16px',
    borderRadius: '5px',
    border: '1px solid #ddd',
  },
  button: {
    backgroundColor: 'transparent',
    color: '#62fbfa',
    border: '1px solid #62fbfa',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '5px',
  },
  secondaryButton: {
    backgroundColor: 'transparent',
    color: '#62fbfa',
    border: '1px solid #62fbfa',
    padding: '10px 20px',
    borderRadius: '5px',
    cursor: 'pointer',
    margin: '5px',
  },
};

export default App;
