import React, { useState, useContext } from 'react';
import { AppContext } from './AppContext';
import {
  collection, addDoc, query, where, onSnapshot, getDoc, setDoc, Timestamp, updateDoc, getFirestore, orderBy, serverTimestamp, doc, getDocs
} from 'firebase/firestore';

const ShareDATPopup = ({ onShare, onCancel, userId, centeredRelease }) => {
  const [amount, setAmount] = useState('');
  const { 
    showShareDATPopup, setShowShareDATPopup, currentUser, setMessages, credits, activePopupMedia, selectedUserIdShare, followers, userId2, selectedProfile
  } = useContext(AppContext);

  const [inputText, setInputText] = useState('');

  const handleShare = async () => {
    try {
      
  
      const currentUserId = userId2;
      const recipientId = selectedUserIdShare;
      const contentId = centeredRelease.contentId;
  
      
      
  
      if (!followers.includes(recipientId)) {
        alert('You can only send messages to users that follow you.');
        return;
      }
  
      if (!currentUserId || !recipientId || !contentId) {
        console.error('Invalid parameters', { currentUserId, recipientId, contentId });
        return;
      }
  
      const db = getFirestore();
      
      const conversationId = [currentUserId, recipientId].sort().join('_');
      
  
      const messageId = `msg_${Date.now()}_${Math.random().toString(36).substr(2, 8)}`;
      
  
      const currentTimestamp = Timestamp.now();
  
      const createOrGetConversationId = async () => {
        try {
          
          const senderSummaryRef = doc(db, 'users', currentUserId, 'conversationsSummary', 'summary');
          const recipientSummaryRef = doc(db, 'users', recipientId, 'conversationsSummary', 'summary');
  
          const senderSummarySnap = await getDoc(senderSummaryRef);
          const recipientSummarySnap = await getDoc(recipientSummaryRef);
  
          if (!senderSummarySnap.exists()) {
            
            await setDoc(senderSummaryRef, { conversations: {} });
          }
          if (!recipientSummarySnap.exists()) {
            
            await setDoc(recipientSummaryRef, { conversations: {} });
          }
  
          const summaryData = {
            unread: false,
            latestMessageTimestamp: currentTimestamp,
            userId: recipientId,
            accountType: selectedProfile?.accountType || '',
            name: selectedProfile?.name || '',
            username: selectedProfile?.username || '',
            profileImageUrl: selectedProfile?.profileImageUrl || '',
            otherUser: {
              userId: recipientId,
              accountType: selectedProfile?.accountType || '',
              name: selectedProfile?.name || '',
              username: selectedProfile?.username || '',
              profileImageUrl: selectedProfile?.profileImageUrl || ''
            }
          };
  
          const recipientSummaryData = {
            unread: true,
            latestMessageTimestamp: currentTimestamp,
            userId: currentUserId,
            accountType: currentUser?.accountType || '',
            name: currentUser?.name || '',
            username: currentUser?.username || '',
            profileImageUrl: currentUser?.profileImageUrl || '',
            otherUser: {
              userId: currentUserId,
              accountType: currentUser?.accountType || '',
              name: currentUser?.name || '',
              username: currentUser?.username || '',
              profileImageUrl: currentUser?.profileImageUrl || ''
            }
          };
  
          await updateDoc(senderSummaryRef, {
            [`conversations.${conversationId}`]: summaryData
          });
  
          await updateDoc(recipientSummaryRef, {
            [`conversations.${conversationId}`]: recipientSummaryData
          });
  
          return conversationId;
        } catch (error) {
          console.error('Error in createOrGetConversationId:', error);
          throw error;
        }
      };
  
      await createOrGetConversationId();
  
      const sendToConversation = async (convId, userId, notificationNeeded) => {
        try {
          
          const messagesRef = doc(db, 'users', userId, 'messages', convId);
  
          const conversationDoc = await getDoc(messagesRef);
          let messages = [];
  
          if (conversationDoc.exists()) {
            const data = conversationDoc.data();
            messages = data.messages || [];
          }
  
          if (!Array.isArray(messages)) {
            messages = [];
          }
  
          const newMessage = {
            id: messageId,
            text: inputText,
            mediaUrl: '',
            mediaType: '',
            userId: currentUserId,
            timestamp: currentTimestamp,
            conversationId: convId,
            notificationNeeded: notificationNeeded,
            contentId: contentId,
            SongName: centeredRelease?.songName || centeredRelease?.albumName || centeredRelease?.SongName,
            ArtistName: centeredRelease?.artistName || centeredRelease?.ArtistName,
            ArtworkURL: centeredRelease?.artworkUrl || centeredRelease?.ArtworkURL || centeredRelease?.VideoThumbnailURL,
            artistUserId: centeredRelease?.userId,
            isAlbum: centeredRelease?.isAlbum || false,
            isVideo: centeredRelease?.isVideo || false,
            isBeat: centeredRelease?.isBeat || false,
            isSPNCast: centeredRelease?.isSPNCast || false,
            category: centeredRelease?.category || '',
            commentary: centeredRelease?.commentary || '',
            platform: centeredRelease?.platform || '',
            username: centeredRelease?.username
          };
  
          messages.push(newMessage);
  
          await setDoc(messagesRef, { messages });
        } catch (error) {
          console.error('Error in sendToConversation:', error);
          throw error;
        }
      };
  
      try {
        await sendToConversation(conversationId, currentUserId, false); 
        await sendToConversation(conversationId, recipientId, true); 
      } catch (error) {
        if (error.code === 'permission-denied') {
          alert('You do not have permission to send messages.');
        } else if (error.code === 'unavailable') {
          alert('Network unavailable. Please try again later.');
        } else if (error.code === 'resource-exhausted') {
          alert('Quota exceeded. Please try again later.');
        } else {
          alert(`Error sending message: ${error.message}`);
        }
        console.error('Error sending message:', error);
      }
  
      setInputText('');
      setShowShareDATPopup(false);
    } catch (error) {
      console.error('Error in handleShare:', error);
    }
  };

  return (
    <div style={styles.popupContainer}>
      <div style={styles.touchableWithoutFeedback}>
        <div style={styles.container}>
          <p style={styles.text}>Message Text (optional)</p>
          <textarea
            style={styles.input}
            placeholder="Enter Message"
            value={inputText}
            onChange={(e) => setInputText(e.target.value)}
          />

          <div style={styles.shareButtonContainer}>
            <button style={styles.buttonContainer} onClick={handleShare}>
              <span style={styles.buttonText}>SHAREDAT</span>
            </button>
          </div>
          <div style={styles.cancelButtonContainer}>
            <button style={styles.buttonContainer} onClick={() => setShowShareDATPopup(false)}>
              <span style={styles.buttonText}>Cancel</span>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  popupContainer: {
    width: '100%',
    height: '50%',
    position: 'absolute',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    zIndex: 800,
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    zIndex: 5,
  },
  text: {
    fontSize: '18px',
    marginTop: '80px',
    marginBottom: '60px',
    color: 'white',
  },
  input: {
    borderWidth: '1px',
    borderColor: 'white',
    display: 'flex',
    position: 'absolute',
    width: '80%',
    height: '50px',  // Adjusted height for a single line input
    fontSize: '18px',
    padding: '10px',
    marginBottom: '20px',
    color: 'white',
    backgroundColor: 'transparent',
    borderRadius: '5px',
    textAlign: 'left',
    lineHeight: '1.5', // Adjusted line-height for better text spacing
    boxSizing: 'border-box',
  },
  shareButtonContainer: {
    marginTop: '50px',
    marginBottom: '20px',
  },
  cancelButtonContainer: {
    marginBottom: '20px',
  },
  buttonContainer: {
    padding: '10px',
    borderRadius: '5px',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: '#6200ea',
    color: 'white',
    cursor: 'pointer',
  },
  buttonText: {
    color: 'white',
    fontSize: '1rem',
  },
  touchableWithoutFeedback: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    height: '100%',
  },
};

export { ShareDATPopup };
