import React, { useState, useEffect, useContext } from 'react';
import { getDownloadURL, ref } from 'firebase/storage';
import { firestore, storage } from './firebase';
import { collection, getDocs, getDoc, doc, updateDoc } from 'firebase/firestore';
import { AppContext } from './AppContext';

const { width, height } = { width: window.innerWidth, height: window.innerHeight };

const bottomAreaHeight = height * 0.18;
const horizontalAreaSpacing = height * 0.015;
const scrollAreaTopPadding = height * 0.16;

const RenderScreenSKNItem = React.memo(({ item }) => {
  const { setUser, currentUser, userId2 } = useContext(AppContext);
  const [songArtworkUrl, setSongArtworkUrl] = useState(null);

  useEffect(() => {
    const fetchArtworkUrl = async (artworkUrl) => {
      try {
        let url;
        if (artworkUrl.startsWith('https://')) {
          url = artworkUrl;
        } else if (artworkUrl.startsWith('gs://')) {
          const imagePath = artworkUrl.split('gs://')[1].split('/').slice(1).join('/');
          url = await getDownloadURL(ref(storage, imagePath));
        }
        setSongArtworkUrl(url);
      } catch (error) {
        console.error('Failed to load image', error);
      }
    };

    const artworkUrl = item?.buttonImage;
    if (artworkUrl) {
      fetchArtworkUrl(artworkUrl);
    }
  }, [item]);

  if (!item) {
    return <div style={styles.songArtworkPlaceholder}></div>;
  }

  const removeEntireScreenSKN = async (screenSKNId) => {
    
    try {
      const userRef = doc(firestore, 'users', userId2);
      const userSnap = await getDoc(userRef);
      if (!userSnap.exists()) {
        throw new Error("User document doesn't exist.");
      }

      

      const userScreenSKNs = userSnap.data()?.ScreenSKN || [];
       // Should log true
      

      const updatedScreenSKNs = userScreenSKNs.filter(skn => skn.screenSKNId !== screenSKNId);
      await updateDoc(userRef, { ScreenSKN: updatedScreenSKNs });

      setUser(prevUser => ({
        ...prevUser,
        ScreenSKN: updatedScreenSKNs,
      }));
    } catch (error) {
      console.error("Error in removeEntireScreenSKN:", error);
      throw error;  // Re-throw the error after logging
    }
  };

  const handleRemoveEntireScreenSKN = (screenSKNItem) => {
    
    removeEntireScreenSKN(screenSKNItem.screenSKNId)
      .then(() => alert("ScreenSKN Removal: ScreenSKN removed successfully."))
      .catch(error => {
        console.error("Error removing ScreenSKN:", error);
        alert("ScreenSKN Removal Error: Failed to remove ScreenSKN.");
      });
  };

  return (
    <div style={styles.itemContainer}>
      <div style={styles.horizontalTouchable}>
        {songArtworkUrl ? (
          <img src={songArtworkUrl} alt="Song Artwork" style={styles.songArtwork} />
        ) : (
          <div style={styles.songArtworkPlaceholder}>
            <div style={styles.loader}></div>
          </div>
        )}
      </div>
      <p style={styles.artistName}>{item.artistName}</p>
      <p style={styles.songName}>
        {item.screenSKNName || 'Unknown ScreenSKN'}
      </p>
      <button onClick={() => handleRemoveEntireScreenSKN(item)} style={styles.removeButton}>
        Remove
      </button>
    </div>
  );
});

const ScreenSKNList = () => {
  const { screenSKNs, currentUser } = useContext(AppContext);
  const [isFetching, setIsFetching] = useState(false);
  const [data, setData] = useState([]);
  const [page, setPage] = useState(0);
  const pageSize = 8;

  useEffect(() => {
    loadData(0); // Load initial data
  }, [screenSKNs]);

  const loadData = (pageNum) => {
    setIsFetching(true);
    const startIndex = pageNum * pageSize;
    const endIndex = startIndex + pageSize;
    const newItems = currentUser?.ScreenSKN.slice(startIndex, endIndex);

    setData(prevData => {
      // Append new items only if they are not already included
      if (startIndex === 0) {
        return [...newItems]; // If it's the first page, replace data
      } else {
        return [...prevData, ...newItems]; // Otherwise, append new data
      }
    });
    setIsFetching(false);  // Set loading to false after data is updated
  };

  const loadMoreItems = () => {
    if (!isFetching && data.length < currentUser?.ScreenSKN.length) {
      loadData(data.length / pageSize);
    }
  };

  return (
    <div style={styles.horizontalSection}>
      {data.map(item => (
        <RenderScreenSKNItem key={item.screenSKNId} item={item} />
      ))}
      {isFetching && <div style={styles.loader}></div>}
    </div>
  );
};

const imageSize = '100px';

const styles = {
  horizontalSection: {
    marginTop: '10px',
    marginBottom: '10px',
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'scroll',
    marginLeft: '20px',
  },
  horizontalText: {
    fontSize: '18px',
    fontWeight: 'bold',
    color: 'white',
    marginBottom: '10px',
  },
  fullScreen: {
    flex: 1,
  },
  scrollContainer: {
    position: 'absolute',
    top: 0,
    width: '100%',
    height: `calc(100% - ${bottomAreaHeight}px)`,
    zIndex: 2,
    overflow: 'visible',
  },
  fullScreenImage: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 0,
  },
  fullScreenImageOverlay: {
    width: '100%',
    height: '100%',
    position: 'absolute',
    zIndex: 10,
  },
  horizontalTouchable: {
    width: imageSize,
    height: imageSize,
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
  },
  horizontalScroll: {
    flexGrow: 0,
  },
  songArtwork: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
  },
  songArtworkPlaceholder: {
    width: '100%',
    height: '100%',
    borderRadius: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#cccccc',
  },
  itemContainer: {
    width: imageSize,
    alignItems: 'flex-start',
    marginRight: '10px',
  },
  artistName: {
    marginTop: '4px',
    color: 'white',
    fontSize: '18px',
    fontWeight: 'bold',
    textAlign: 'left',
  },
  songName: {
    marginTop: '2px',
    color: '#b8b8b8',
    fontSize: '18px',
    textAlign: 'left',
    fontWeight: 'regular',
  },
  removeButton: {
    alignSelf: 'center',
    color: '#62fbfa',
    fontSize: '16px',
    fontWeight: 'bold',
    textAlign: 'center',
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    marginTop: '5px',
  },
  loader: {
    border: '8px solid #f3f3f3',
    borderRadius: '50%',
    borderTop: '8px solid #3498db',
    width: '60px',
    height: '60px',
    animation: 'spin 2s linear infinite',
  },
  '@keyframes spin': {
    '0%': { transform: 'rotate(0deg)' },
    '100%': { transform: 'rotate(360deg)' },
  },
};

export default ScreenSKNList;
