import React, { useState, useEffect, useRef, useContext } from 'react';
import { AppContext } from './AppContext';
import { doc, getDoc, collection, query, where, getDocs, limit } from 'firebase/firestore';
import { firestore } from './firebase';
import { ProfileComponentFollowing } from './ProfileComponentFollowing';
import { ShareCreditsPopup } from './ShareCreditsPopup';
import Modal from 'react-modal';
import { useNavigate } from 'react-router-dom';
import { debounce } from 'lodash';
import { usePlaidLink } from 'react-plaid-link';
import { getLinkToken } from './PlaidManager';
import { useStripe, useElements, PaymentElement } from '@stripe/react-stripe-js';

import { getFunctions, httpsCallable } from 'firebase/functions';

const ProfilesDisplayShareCredits = ({ setClientSecret, clientSecret, on1, on2, on3, on4, onProfilePress }) => {
  const [profiles, setProfiles] = useState([]);
  const [filteredProfiles, setFilteredProfiles] = useState([]);
  const [searchText, setSearchText] = useState('');
  const { selectedUserIdShare, setShowSuccessAlertShareCredits, setCurrentlySharingCredits, showCreditSharePopup, sharePostActive, setSharePostActive, followers, setShowCreditSharePopup, userId2, setSelectedUserIdShare } = useContext(AppContext);
  const [isSelecting, setIsSelecting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const isLoadingRef = useRef(false);
  const [linkToken, setLinkToken] = useState(null); // Store the Plaid link token
  const [accessToken, setAccessToken] = useState(null); // Store the access token after exchange
  const [selectedCredits, setSelectedCredits] = useState(null); // To store selected credits
  const [selectedCost, setSelectedCost] = useState(null);

  const [loading, setLoading] = useState(false);
  const [selectedPriceId, setSelectedPriceId] = useState(null);
  const [showPaymentForm, setShowPaymentForm] = useState(false);

  const functions = getFunctions();

  const stripe = useStripe();
  const elements = useElements();


  

  const products = [
    { credits: 50, cost: '5.69', priceId: 'price_1QaPxgBqCybQOhrecA8YY5iJ' },
    { credits: 100, cost: '10.87', priceId: 'price_1QaPy8BqCybQOhreOLPpLMLM' },
    { credits: 200, cost: '21.24', priceId: 'price_1QaPyfBqCybQOhreb1BATjop' },
    { credits: 250, cost: '26.31', priceId: 'price_1QaPz8BqCybQOhre0fg6aBoC' },
    { credits: 400, cost: '41.97', priceId: 'price_1QaQ04BqCybQOhreUPdIlojw' },
  ];

  const handleAddCredits = async (credits) => {
    const addCreditsWeb = httpsCallable(functions, 'addCreditsWeb');
  
    try {
      const response = await addCreditsWeb({ credits });
      if (response.data.status === 'success') {
        alert(`Successfully added ${credits} credits to your account.`);
        console.log('Credits added successfully:', response.data);
      } else {
        alert('Failed to add credits. Please try again.');
        console.error('Credits addition failed:', response.data);
      }
    } catch (error) {
      console.error('Error adding credits:', error);
      alert('An error occurred while adding credits. Please try again.');
    }
  };


  const onSuccess = async (publicToken, metadata) => {
    const exchangePublicToken = httpsCallable(functions, 'exchangePublicToken2');
  
    try {
      const response = await exchangePublicToken({ public_token: publicToken });
      console.log('Access Token:', response.data.access_token);
      console.log('Item ID:', response.data.item_id);
  
      // Assuming `selectedCredits` contains the purchased credit amount
      if (selectedCredits) {
        await handleAddCredits(selectedCredits); // Call the cloud function to add credits
      }
  
      alert('Bank account successfully linked, and credits have been added!');
    } catch (error) {
      console.error('Error exchanging public token:', error);
      alert('Failed to link bank account or add credits. Please try again.');
    }
  };
  
  

  const { open, ready } = usePlaidLink({
    token: linkToken,
    onSuccess,
  });



  const navigate = useNavigate();

  const [paypalButton, setPaypalButton] = useState(false);
  const [paypalButtonsLoaded, setPaypalButtonsLoaded] = useState(false);



  const clientId = 'AUPVBkAu1c37lWNuP2jimRK5RnbpOs4pPv8ft6YYTBTS3RANAZ3bIoSaGUG4ymLDuLbTz6gh4QCxinmV'; 

  const loadPayPalScript = (clientId) => {
    return new Promise((resolve, reject) => {
      if (window.paypal) {
        resolve(window.paypal);
        return;
      }
      
      const script = document.createElement('script');
      script.src = `https://www.paypal.com/sdk/js?client-id=${clientId}`;
      script.onload = () => {
        if (window.paypal) {
          resolve(window.paypal);
        } else {
          reject(new Error('PayPal SDK could not be loaded.'));
        }
      };
      script.onerror = () => reject(new Error('PayPal SDK could not be loaded.'));
      document.body.appendChild(script);
    });
  };

  const loadPayPal = async () => {
    try {
      await loadPayPalScript(clientId);
    } catch (error) {
      console.error("Failed to load PayPal SDK", error);
    }
  };



  const handlePayment = async (priceId, credits) => {
    setSelectedPriceId(priceId);
    setSelectedCredits(credits); // Ensure credits are set
    setLoading(true);
  
    try {
      const createPaymentIntent = httpsCallable(functions, 'createPaymentIntent');
      const response = await createPaymentIntent({ priceId });
  
      if (response.data && response.data.clientSecret) {
        console.log("New Client Secret:", response.data.clientSecret);
        setClientSecret(response.data.clientSecret); // Set a fresh clientSecret
        setShowPaymentForm(true);
      } else {
        throw new Error("Failed to retrieve clientSecret from backend.");
      }
    } catch (error) {
      console.error('Error creating PaymentIntent:', error);
      alert('Failed to initiate payment. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  
  

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!stripe || !elements) {
      console.error("Stripe or Elements not initialized.");
      return;
    }
  
    try {
      const { error, paymentIntent } = await stripe.confirmPayment({
        elements,
        redirect: "if_required",
      });
  
      if (error) {
        console.error("Stripe confirmation error:", error.message);
        alert(`Payment failed: ${error.message}`);
        return;
      }
  
      if (paymentIntent && paymentIntent.status === "succeeded") {
        console.log("PaymentIntent Success:", paymentIntent);
  
        // Call cloud function to add credits
        const addCreditsWeb = httpsCallable(functions, "addCreditsWeb");
        const response = await addCreditsWeb({ credits: selectedCredits });
  
        if (response.data && response.data.status === "success") {
          setShowSuccessAlertShareCredits(true);
          alert("Payment successful! Credits have been added.");
        } else {
          console.error("Failed to add credits:", response.data);
          alert("Payment succeeded, but adding credits failed. Please contact support.");
        }
  
        // Clear clientSecret to avoid reuse
        setClientSecret(null);
        setShowPaymentForm(false);
      }
    } catch (err) {
      console.error("Error during payment process:", err.message);
      alert("An unexpected error occurred. Please try again later.");
      setClientSecret(null); // Clear clientSecret on failure
    }
  };
  
  


  
  const handlePayPalPayment = async (credits, cost) => {
    alert('Credits can currently only be purchased through the SPNDAT app on iOS or Android')
  };

  const handleShareCredits = async (amount) => {
    setCurrentlySharingCredits(true);
    setShowCreditSharePopup(false);
    const transferCredits = httpsCallable(functions, 'transferCredits');
 
    try {
       // Detailed logging of selectedUserIdShare
      const response = await transferCredits({
        receiverId: selectedUserIdShare,
        amount: amount // the amount of credits to transfer
      });
 
      
      setShowSuccessAlertShareCredits(true);
      setCurrentlySharingCredits(false);
    } catch (error) {
      setCurrentlySharingCredits(false);
      console.error("handleShareCredits: Error sharing credits:", error);
    }
  };

  useEffect(() => {
    if (isSelecting) {
      onProfilePress();
      setIsSelecting(false);
    }
  }, [isSelecting, onProfilePress]);

  useEffect(() => {
    loadInitialProfiles();
  }, [followers]);

  const loadInitialProfiles = async () => {
    if (Array.isArray(followers)) {
      setIsLoading(true);
      let loadedProfiles = [];
      for (const userId of followers.slice(0, 9)) {
        if (userId !== userId2) {
          const userData = await getCachedUserDocMinimal(userId);
          if (userData) {
            loadedProfiles.push(userData);
          }
        }
      }
      setProfiles(loadedProfiles);
      setFilteredProfiles(loadedProfiles);
      setIsLoading(false);
    } else {
      console.warn("Followers data is not valid.");
    }
  };

  const getCachedUserDocMinimal = async (userId) => {
    let userDataCached = [];

    const userDocRef = doc(firestore, 'users', userId);
    const userDocSnap = await getDoc(userDocRef);

    if (userDocSnap.exists()) {
      const freshUserData = userDocSnap.data();
      userDataCached = {
        ...freshUserData,
        timestamp: new Date().getTime()
      };

      return {
        userId: userId,
        voiceDropMuted: userDataCached.voiceDropMuted,
        accountType: userDataCached.accountType,
        name: userDataCached.name,
        username: userDataCached.username,
        profileImageUrl: userDataCached.profileImageUrl
      };
    } else {
      console.error(`No document found in Firestore for userId: ${userId}`);
      return null;
    }
  };

  const handleProfileSelect = async (profileData) => {
    const selectedUserId = profileData.userId;
    setSelectedUserIdShare(selectedUserId);
    setShowCreditSharePopup(true);
  };

  const fetchProfilesFromFirestore = async (searchText) => {
    if (!searchText.trim()) {
      setFilteredProfiles(profiles); // Show initial batch when search is empty
      return;
    }
  
    const searchTextLower = searchText.toLowerCase().trim();
    const usernameSearchText = searchTextLower.replace(/\s+/g, ''); // Remove spaces for username search
  
    setIsLoading(true);
  
    try {
      // Query for username fields matching the search text partially
      const userQuery = query(
        collection(firestore, 'users'),
        where('username_lower', '>=', searchTextLower),
        where('username_lower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      // Query for artist names matching the search text partially
      const userArtistNameQuery = query(
        collection(firestore, 'users'),
        where('ArtistNameLower', '>=', searchTextLower),
        where('ArtistNameLower', '<=', searchTextLower + '\uf8ff'),
        limit(7)
      );
  
      // Query for exact username match with spaces removed
      const exactUsernameQuery = query(
        collection(firestore, 'users'),
        where('username_lower', '==', usernameSearchText),
        limit(7)
      );
  
      const results = await Promise.all([
        getDocs(userQuery),
        getDocs(userArtistNameQuery),
        getDocs(exactUsernameQuery)
      ]);
  
      const combinedItems = results.flatMap((snap) => 
        snap.docs.map(doc => ({
          ...doc.data(),
          id: doc.id,
          type: 'user'
        }))
      );
  
      const uniqueItems = Object.values(combinedItems.reduce((acc, item) => {
        const key = `${item.id}_${item.type}`;
        if (!acc[key]) {
          acc[key] = item;
        }
        return acc;
      }, {}));
      
      // Sort by username matching name without spaces
      uniqueItems.sort((a, b) => {
        const aNameNoSpaces = a.name.replace(/\s+/g, '').toLowerCase();
        const bNameNoSpaces = b.name.replace(/\s+/g, '').toLowerCase();
        const aUsername = a.username.toLowerCase();
        const bUsername = b.username.toLowerCase();
      
        if (aUsername === aNameNoSpaces && bUsername !== bNameNoSpaces) return -1;
        if (aUsername !== aNameNoSpaces && bUsername === bNameNoSpaces) return 1;
      
        // Prioritize "Artist" account type
        if (a.accountType === 'Artist' && b.accountType !== 'Artist') return -1;
        if (a.accountType !== 'Artist' && b.accountType === 'Artist') return 1;
      
        return 0;
      });
  
      setFilteredProfiles(uniqueItems);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (text) => {
    setSearchText(text);
    debounceFetchProfiles(text);
  };

  const debounceFetchProfiles = debounce((text) => {
    fetchProfilesFromFirestore(text);
  }, 600);

  const handlePlaidOpen = (credits, cost) => {
    setSelectedCredits(credits);
    setSelectedCost(cost);
    if (ready) {
      open(); // Open the Plaid Link flow
    } else {
      alert('Plaid is not ready yet. Please try again.');
    }
  };

  return (
    <div style={styles.container}>
      <img
        src={require('./assets/creditShareText2.png')}
        style={styles.CreditsDropdownImage}
        alt="Credit Share"
      />
      {/* Buttons for purchasing credits */}
      <div style={styles.buttonGridContainer}>
        {products.map((product) => (
          <button
  key={product.priceId}
  style={styles.transparentButton}
  onClick={() => handlePayment(product.priceId, product.credits)} // Pass credits
  disabled={loading}
>
</button>
        ))}
      </div>
  
      {showPaymentForm && clientSecret && (
  <div
    style={{
      position: 'absolute', // Ensures the position remains fixed relative to the parent container
      top: '37%',           // Center vertically
      left: '50%',          // Center horizontally
      transform: 'translate(-50%, -50%)', // Perfect centering trick
      zIndex: 9999,         // High z-index for visibility
      backgroundColor: 'rgba(0, 0, 0, 0.9)', // Semi-transparent background
      borderRadius: '8px',
      padding: '20px',
      width: '100%',        // Full width within parent container
      maxWidth: '540px',    // Control maximum width
    }}
  >
    <form onSubmit={handleSubmit} style={{ width: '100%' }}>
      <PaymentElement />
      <button
        type="submit"
        style={{
          marginTop: '20px',
          padding: '10px',
          backgroundColor: '#635bff',
          color: '#fff',
          fontSize: '16px',
          border: 'none',
          borderRadius: '4px',
          cursor: 'pointer',
        }}
        disabled={!stripe || loading}
      >
        {loading ? 'Processing...' : 'Pay Now'}
      </button>
    </form>
  </div>
)}


  
      {/* Search Input */}
      <div style={styles.container2}>
        <input
          style={styles.searchInput}
          placeholder="Search..."
          value={searchText}
          onChange={(e) => handleSearch(e.target.value)}
        />
        <div style={styles.scrollableContainer}>
          <div style={styles.listContainer}>
            {filteredProfiles.map((profile) => (
              <ProfileComponentFollowing
                key={profile.userId}
                userProfile={profile}
                onPress={() => handleProfileSelect(profile)}
              />
            ))}
          </div>
        </div>
      </div>
  
      {/* Share Credits Modal */}
      <Modal
        isOpen={showCreditSharePopup}
        onRequestClose={() => setShowCreditSharePopup(false)}
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.85)', zIndex: 10000 },
          content: {
            height: '400px',
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: 'transparent',
            border: 'none',
          },
        }}
      >
        <div style={styles.sharingPost}>
          <ShareCreditsPopup onShare={handleShareCredits} />
        </div>
      </Modal>
  
      {/* PayPal Placeholder */}
      <Modal
        isOpen={paypalButton}
        onRequestClose={() => setPaypalButton(false)}
        style={{
          overlay: { backgroundColor: 'rgba(0, 0, 0, 0.85)', zIndex: 10000 },
          content: {
            top: '50%',
            left: '50%',
            height: '400px',
            width: '80%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'transparent',
            border: 'none',
          },
        }}
      >
        <div style={styles.sharingPost}>
          {!paypalButtonsLoaded && <div style={styles.loadingText}>Loading Checkout...</div>}
          <div id="paypal-button-container"></div>
        </div>
        {paypalButtonsLoaded && (
          <div style={styles.cancelContainer}>
            <button style={styles.cancelButton} onClick={() => setPaypalButton(false)}>
              Cancel
            </button>
          </div>
        )}
      </Modal>
    </div>
  );
};  

const styles = {
  container: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  addCreditsContainer1: {
    display: 'flex',
    width: '100%',
    height: '20%',
    top: '5%',
    position: 'absolute',
    margin: '0 auto',
    flexDirection: 'column',
    zIndex: 90000000000,
    opacity: 0.3,
    backgroundColor: 'transparent',
  },
  addCreditsContainer2: {
    display: 'flex',
    width: '100%',
    height: '20%',
    top: '5%',
    position: 'absolute',
    margin: '0 auto',
    flexDirection: 'column',
    zIndex: 90000000,
    opacity: 0.3,
    backgroundColor: 'transparent',
  },
  addCreditsContainer3: {
    display: 'flex',
    width: '100%',
    height: '20%',
    top: '5%',
    position: 'absolute',
    margin: '0 auto',
    flexDirection: 'column',
    zIndex: 900000000,
    opacity: 0.3,
    backgroundColor: 'transparent',
  },
  container2: {
    marginTop: '180px',
    minHeight: '180px',
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    overflow: 'hidden',
  },
  sharingPost: {
    width: '100%',
    height: '200px',
    display: 'flex',
    alignSelf: 'center',
    justifyContent: 'center',
  },
  cancelContainer: {
    display: 'flex',
    width: '100%',
    height: '100%',
    margin: '0 auto',
    justifyContent: 'center',
    alignSelf: 'center',
  },
  cancelButton: {
    padding: '10px 20px',
    marginBottom: '20px',
    display: 'flex',
    justifyContent: 'center',
    alignSelf: 'center',
    margin: '0 auto',
    position: 'absolute',
    top: '180px',
    backgroundColor: 'transparent',
    color: '#62fbfa',
    border: 'none',
    fontSize: '20px',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  loadingText: {
    fontSize: '20px',
    color: '#62fbfa',
    marginBottom: '10px',
  },
  CreditsDropdownImage: {
    position: 'absolute',
    top: '-11vh',
    height: '100%',
    zIndex: 900,
  },
  searchInput: {
    height: '4vh',
    width: '75%',
    borderColor: 'rgba(189, 252, 251, 0.6)',
    fontSize: '16px',
    backgroundColor: 'transparent',
    borderWidth: 1,
    marginBottom: '2vh',
    borderRadius: 5,
    paddingLeft: 10,
    color: 'white',
    fontFamily: 'brhendrix',
    zIndex: 9000,
  },
  transparentButton: {
    position: 'relative',
    width: '130px',
    height: '35px',
    left: '30px',
    alignSelf: 'flex-start',
    padding: 10,
    zIndex: 900000000,
    backgroundColor: 'transparent',
    border: 'none',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  transparentButton2: {

    
    position: 'relative',
    width: '130px',
    height: '35px',
    left: '160px',

    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
    padding: 10,
    zIndex: 900000000,
    border: 'none',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  transparentButton3: {
    position: 'relative',
    width: '130px',
    height: '35px',
    left: '290px',

    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
    padding: 10,
    zIndex: 900000000,
    border: 'none',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  transparentButton4: {
    position: 'relative',
    width: '130px',
    height: '35px',
    left: '30px',
    marginTop: '0.2vh',
    marginBottom: '0.2vh',
    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
    padding: 10,
    zIndex: 900000,
    border: 'none',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  transparentButton5: {
    position: 'relative',
    width: '130px',
    height: '35px',
    left: '290px',

    alignSelf: 'flex-start',
    backgroundColor: 'transparent',
    padding: 10,
    zIndex: 9000,
    border: 'none',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  transparentButton6: {
    position: 'relative',
    width: '130px',
    height: '35px',
    left: '290px',
    marginTop: '0.2vh',
    backgroundColor: 'transparent',
    alignSelf: 'flex-start',
    padding: 10,
    zIndex: 9000,
    border: 'none',
    cursor: 'pointer',
    pointerEvents: 'auto',
  },
  activityIndicator: {
    marginTop: '2vh',
    left: '8vw',
  },
  scrollableContainer: {
    display: 'flex',
    width: '100%',
    overflowX: 'auto',
    zIndex: 9000,
    marginBottom: '20px',
  },
  listContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: '1rem',
    padding: '1rem',
  },
  buttonGridContainer: {
    display: 'grid',
    gridTemplateColumns: 'repeat(3, 1fr)', // 3 columns for the grid
    gap: '2px',
    width: '100%',
    marginRight: '7px',
    maxWidth: '400px', // Adjust width as needed
    marginTop: '60px', // Center the grid container
    padding: '10px',
  },
  transparentButton: {
    width: '110px',
    height: '30px', // Adjust button height as needed
    backgroundColor: 'transparent',
    border: 'none', // Optional for visibility
    borderRadius: '5px',
    color: 'blue',
    zIndex: 900000,
    cursor: 'pointer',
    fontSize: '16px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
};

export default ProfilesDisplayShareCredits;
