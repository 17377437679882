import React, { useContext, useMemo } from 'react';
import { AppContext } from './AppContext';
import { useNavigate } from 'react-router-dom';

const ArtistsScreenSettings = ({ navigation }) => {
  const navigate = useNavigate();
  const { currentUser, purchasedMedia } = useContext(AppContext);

  const artists = useMemo(() => {
    if (!currentUser || !currentUser.name) return [];

    const allArtists = purchasedMedia
      .map(item => item.ArtistName)
      .filter(artistName => artistName !== currentUser.name); // Exclude current user's name

    return [...new Set(allArtists)].sort(); // Remove duplicates and sort
  }, [purchasedMedia]); // Depend on currentUser.name as well

  const categories = [
    { name: 'Edit Login', route: 'PasswordChange' },
    { name: 'Multi-Factor Authentication', route: 'Multifactor' },
    { name: 'Personal Information', route: 'PersonalInfo' },
    { name: 'CASHOUT Details', route: 'CASHDATEmail' },
    { name: 'Report', route: 'Report' },
    { name: 'Support', route: 'Support' },
    { name: 'Terms Of Service', route: 'Terms' },
    { name: 'Delete Account', route: 'DeactivateAccount' },
  ];

  const handleSelectCategory = (category) => {
    navigate(`/mydat/settings/${category.route.toLowerCase()}`);
  };

  const filteredCategories = categories.filter(category => {
    if (['PasswordChange', 'Multifactor', 'PersonalInfo', 'Report', 'Support', 'Terms', 'DeactivateAccount'].includes(category.route)) {
      return true;
    }
    return currentUser.accountType !== 'Fan' && category.route === 'CASHDATEmail';
  });

  return (
    <div style={styles.fullScreen}>
      <div style={styles.container}>
        <div style={styles.headerContainer}>
          <h2 style={styles.headerText}>SETTINGS</h2>
        </div>
        <ul style={styles.scrollContainer}>
          {filteredCategories.map((category, index) => (
            <li key={`category-${index}`} style={styles.listItem}>
              <button onClick={() => handleSelectCategory(category)} style={styles.genreItem}>
                <div style={styles.row}>
                  <img src={require('./assets/dot.png')} alt="dot" style={styles.dotImage} />
                  <span style={styles.genreText}>{category.name}</span>
                </div>
              </button>
              <div style={styles.borderContainer}>
                <div style={styles.centeredBorder}></div>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

const styles = {
  fullScreen: {
    display: 'flex',
    flexDirection: 'column',
    height: '100vh',
    alignItems: 'center',
  },
  container: {
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: 'transparent',
    zIndex: 10,
    height: '82vh',
  },
  row: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  genreItem: {
    backgroundColor: 'transparent',
    border: 'none',
    padding: '1vh',
    margin: '1vh 0',
    cursor: 'pointer',
    zIndex: 10,
    textAlign: 'left',
    width: '100%',
  },
  genreText: {
    color: 'white',
    fontSize: '2.4vh',
    fontWeight: 'bold',
    fontFamily: 'brhendrix',
  },
  scrollContainer: {
    listStyleType: 'none',
    margin: 0,
    padding: 0,
    position: 'relative',
    width: '100%',
    height: '130%', // Adjust for bottom area height
    zIndex: 2,
    overflowY: 'auto',
  },
  borderContainer: {
    width: '100%',
    alignItems: 'center',
    marginTop: '0',
  },
  centeredBorder: {
    marginLeft: '18%',
    width: '110%',
    borderBottomWidth: '1px',
    borderBottomStyle: 'solid',
    borderBottomColor: 'rgba(189, 252, 251, 0.3)',
  },
  headerContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '1vh 0',
    width: '100%',
    backgroundColor: 'transparent',
    zIndex: 10,
  },
  headerText: {
    color: 'white',
    fontSize: '2.5vh',
    fontWeight: 'bold',
    textAlign: 'center',
    fontFamily: 'brhendrix',
  },
  listItem: {
    marginBottom: '1vh',
  },
  dotImage: {
    width: '2vh',
    height: '2vh',
    marginRight: '10px',
  },
};

export default ArtistsScreenSettings;
